
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  data(): any {
    return {
      ongoingState: false,
      fullImg: false,
    };
  },
  computed: {
    ...mapGetters([
      'visualisationUrl',
    ]),
  },
  methods: {
    showFullImg() {
      this.fullImg ? this.fullImg = false : this.fullImg = true;
    },
    setOngoingState() {
      this.ongoingState = !this.ongoingState;
    },
    getVisualisation() {
      this.setOngoingState();
      this.$store.dispatch('getVisualisationData').then((response: any) => {
        this.setOngoingState();
      });
    },
  },
});
